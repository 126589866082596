import { helperMixin, infinityScrollTable } from "@/mixins";
import { defineComponent } from "vue";
import fleetHttp from "@/http/fleet";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
export default defineComponent({
    components: {
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        ActionTableColumn,
        ConfirmModal
    },
    mixins: [
        helperMixin,
        infinityScrollTable(fleetHttp())
    ]
});
